@import 'src/common/sass/common';
.FooterArea{
  width: 100vw;
  height: 200px;
  background-color: #f8f8f8;
  >div{
    width: 1300px;
    height: 100%;
    margin: auto;
    display: flex;
    >img{
      width: 200px;
      height: 100%;
      content: url("../../asset/Header/HeaderLogo.png");
      object-fit: contain;
    }
    p{
      display: flex;
      width: 1000px;
      height: 100%;
      align-items: center;
      line-height: 2.5;
      font-size: 12px;
    }
    >div {
      width: 200px;
      height: 100%;
      @include flexCenter;

      .ant-select {
        width: 112px;
      }

      .cascader {
        .ant-select-selector {
          background: transparent;
          width: 112px;
        }

        .ant-select-selection-placeholder {
          font-size: 10px;
          text-align: center;
          color: #999;
        }

        .ant-select-arrow {
          right: 15px;
        }
      }
    }
  }
  @media (min-width:768px) and (max-width:1300px){
    >div{
      width: 800px;
      height: 100%;
      margin: auto;
      display: flex;
      >img{
        width: 200px;
        height: 100%;
      }
      p{
        width: 50vw;
      }
      >div {
        width: 180px;
        height: 100%;
        @include flexCenter;

      }
    }
  }
  @media (min-width:200px) and (max-width:768px){
    height: 240px;
    >div{
      width: 100vw;
      height: 100%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      >img{
        order: 1;
        width: 150px;
        height: 40%;
        //transform: translateX(-40px);
      }
      p{
        order: 3;
        width: 310px;
        height: 55%;
        align-items: start;
      }
      >div {
        order: 2;
        width: 200px;
        height: 40%;
        @include flexCenter;
      }
    }
  }
}