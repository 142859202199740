@import 'src/common/sass/common';
.MainSection{
    width: 100vw;
    height: content-box;
    .mainVisual {
        width: 100%;
        height: 600px;
        background-image: url("../../../src/asset/main.jpg");
        background-size: cover;
        background-attachment: fixed;
        position: relative;
        .mainTitleArea {
            width: 100%;
            position: absolute;
            @include positionSet(absolute, 60%, none, none, 50%, translate(-50%, -50%));
            @include flexSet(column, center, center);
            h1 {
                margin-bottom: 30px;
                text-align: center;
                @include mainTitleStyle;
            }

            .titleUnderLine {
                margin: auto;
                width: 85%;
                height: 1px;
                background-color: #eaeaea;
                //@include positionSet(absolute, none, none, 5%, 50%, translateX(-50%));
            }
        }
    }
    .fourcardSection{
        width: 100%;
        height: 800px;
        background-image: url("../../../src/asset/back3.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        >div:first-child{
            width: 100%;
            height: 60%;
            @include flexSet(column, center, center);
            h1{
                @include contentTitleStyle;
                margin-bottom: 30px;
            }
            h2{
                @include contentSubStyle;
                text-align: center;
            }
        }
        >div:last-child{
            width: 1280px;
            height: 30%;
            margin: auto;
            @include flexSet(row, space-between, center);
            >div{
                width: 22%;
                height: 100%;
                padding: 15px 0;
                background-color: rgba(227, 223, 222, 0.65);
                border-radius: 10px;
                box-shadow: 1px 1px 6px 1px rgba(128, 128, 128, 0.53);
                img{
                    width: 100%;
                    height: 80px;
                    padding-top: 20px;
                    object-fit: contain;
                }
                h3{
                    font-weight: bold;
                    font-size: 20px;
                    text-align: center;
                    margin-top: 15px;
                    //color: #dc152e;
                    color: #333;
                }
                >p{
                    padding: 20px;
                    text-align: center;
                    line-height: 1.5;
                    color: #777;
                    font-size: 14px;
                    word-break: keep-all;
                }
            }
            div:last-child{
                border-right: none;
            }
        }
    }
    @media (min-width:768px) and (max-width:1280px){
        .mainVisual{
            .mainTitleArea{
                h1{
                    font-size: 30px;
                    span{
                        font-size: 30px;
                        h5{
                            font-size: 30px;
                        }
                    }
                }
                h2{
                    font-size: 20px;
                }
            }

        }
        .fourcardSection{
            height: 700px;
            padding-bottom: 50px;
            >div:first-child{

            }
            >div:last-child{
                width: 90%;
                height: 38%;
                display: flex;
                align-items: flex-start;
                >div{
                    h3{
                        font-size: 15px;
                        font-family: GmarketSans;
                    }
                    >p{
                        //display: none;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    @media (min-width:200px) and (max-width:768px){
        .mainVisual{
            background-image: url("../../../src/asset/mainMobile.jpg");
            background-attachment: local;
            height: 350px;
            .mainTitleArea{
                top: 60%;
                h1{
                    width: 100%;
                    @include flexCenter;
                    padding-left: 0rem;
                    //font-size: 15px;
                    text-shadow: 0px 0px 2px black;
                    white-space: pre-wrap;
                    margin-bottom: 0;
                    line-height: 2;
                    span{
                        font-size: 20px;
                        margin: 5px;
                        h5{
                            font-size: 20px;
                        }
                    }
                }
                .titleUnderLine{
                    display: none;
                }
                h2{
                    width: 100%;
                    @include flexCenter;
                    padding-left: 0rem;
                    font-size: 18px;
                }
                .titleUnderLine{
                    width: 90%;
                    margin: auto;
                }
            }
        }
        .fourcardSection{
            width: 100%;
            height: 600px;
            margin-bottom: 50px;
            >div:first-child{
                height: 40%;
            }
            >div:last-child{
                background-color: rgba(255, 255, 255, 0);
                backdrop-filter: blur(0px);
                box-shadow: none;
                width: 90%;
                height: fit-content;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                >div{
                    border-right: none;
                    width: 100px;
                    padding: 10px;
                    margin: 0 20px;
                    margin-bottom: 50px;
                    background-color: #ffffff;
                    border-radius: 5px;
                    box-shadow: 1px 1px 5px 1px gray;
                    img{
                        padding-top: 0px;
                        height: 60px;
                    }
                    h3{
                        font-size: 12px;
                    }
                    >p{
                        display: none;
                    }
                }
            }
        }
    }
    @media (min-width: 534px) and (max-width: 711px){
        .fourcardSection{
            >div:first-child{

            }
            >div:last-child{
                width: 90%;
                >div{
                    margin: 0px 60px;
                    margin-bottom: 50px;
                    h3{
                    }
                    >p{
                    }
                }
            }
        }
    }
}
