@import 'src/common/sass/common';
.UpButton{
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 60000;
  @include flexCenter;
  box-shadow: 1px 1px 3px 1px rgba(51, 51, 51, 0.25);
  background-color: rgba(255, 255, 255, 0.87);
  border: 4px solid #ff7070;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
  &:hover{
    background-color: #ff7070;
    border: 4px solid #efefef;
    *,path{
      color: white;
    }
  }
    @media (min-width: 200px) and (max-width: 768px){
      width: 40px;
      height: 40px;
      bottom: 35px;
      right: 20px;
    }
}