@import 'src/common/sass/common';
.ContactSection{
  width: 100%;
  margin: auto;
  background: url("../../../src/asset/back2.jpg");
  background-size: cover;
  padding-bottom: 50px;
  .kakaomapBox {
    margin: auto;
    background: rgba(255, 255, 255, 1);
    width: 1200px;
    height: 600px;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    >div:first-child{
      padding-bottom: 20px;
      @include flexSet(column, center, center);
      >div{
        height: 100%;
        width: 100%;
        @include flexSet(row, flex-start, center);
        margin: 10px;
        position: relative;
        .icon{
          width: 20px;
          height: 20px;
          padding: 10px;
          border-radius: 50%;
          background: #e7e7e7;
          color: black;
        }
        >div{
          @include flexSet(column, center, center);
          width: 100%;
          height: 100%;
          overflow: hidden;
          >h1{
            display: inline-block;
            width: 100%;
            height: 100%;
            @include flexSet(row, flex-start, center);
            padding-left: 40px;
            font-size: 12px;
          }
          >p{
            display: inline-block;
            width: 100%;
            height: 100%;
            @include flexSet(row, flex-start, center);
            padding-left: 40px;
            font-size: 15px;
            cursor: pointer;
            a{
              font-size: 15px;
              color: black;
            }
          }
        }

      }
    }
    >div:last-child{
      overflow: hidden;
      >div{
        height: 100%;
      }
      border: 1px solid lightgray;
    }
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    width: 100%;
    .kakaomapBox{
      width: 700px;
      margin: auto;
      margin-bottom: 100px;
    }
  }
  @media (min-width: 200px) and (max-width: 768px) {
    width: 100%;
    .kakaomapBox{
      width: 300px;
      margin: auto;
      margin-bottom: 100px;
      padding: 10px 15px;
      >div:first-child{
        flex: 2;
        @include flexSet(column, center, center);
        >div{
          height: 100%;
          width: 100%;
          @include flexSet(row, flex-start, center);
          margin: 10px;
          .icon{
            width: 15px;
            height: 15px;
            padding: 5px;
            border-radius: 50%;
            background: #e7e7e7;
            color: black;
          }
          >div{
            @include flexSet(column, center, center);
            width: 100%;
            height: 100%;
            overflow: hidden;
            >h1{
              display: inline-block;
              width: 100%;
              height: 100%;
              @include flexSet(row, flex-start, center);
              padding-left: 20px;
              font-size: 10px;
            }
            >p{
              display: inline-block;
              width: 100%;
              height: 100%;
              @include flexSet(row, flex-start, center);
              font-size: 10px;
              padding-left: 20px;
              word-break: break-word;
              a{
                font-size: 10px;
              }
            }
          }
        }
      }
      >div:last-child{

      }
    }
  }
}
.kakaomap_err{
  content: url("../../../src/asset/map.png");
  @media (min-width: 768px) and (max-width: 1280px){
    content: url("../../../src/asset/map_tab.png");
  }
  @media (min-width: 200px) and (max-width: 768px){
    content: url("../../../src/asset/map_phone.png");
  }
}