@import 'src/common/sass/common';
.SectionTitleArea{
  margin: auto;
  width: 1280px;
  height: 250px;
  @include flexSet(column, center, flex-start);
  >h1{
    @include sectionTitleStyle;
    white-space: pre-wrap;
  }
  >h2{
    @include sectionSubTitleStyle;
    white-space: pre-wrap;
    line-height: 1.5;
  }
  @media (min-width:768px) and (max-width:1280px) {
    height: 200px;
    margin-bottom: 30px;
  }
  @media (min-width:200px) and (max-width:768px){
    height: 150px;
    margin-bottom: 10px;
  }
}