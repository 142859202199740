@import 'src/common/sass/common';

.PortfolioSection{
  background-color: #F6F7FA;
  padding-bottom: 100px;
  background: url("../../../src/asset/back.jpg");
  background-size: cover;
  background-attachment: fixed;
  >div:last-child{
    width: 50%;
    margin: auto;
  }
  .ant-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    span{
      font-size: 14px;
      margin-right: 5px;
    }
  }
  @media (min-width: 200px) and (max-width: 768px){
    background-size: cover;
    .ant-btn{
      span{
        font-size: 12px;
      }
    }
  }
}