@import "src/common/sass/common";

.TechnologySection{
  height: fit-content;
  @include flexSet(column, center, space-between);
  //padding-top: 100px;
  background-image: url("../../../src/asset/techBack.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  >div:nth-child(odd){
    >div{
      display: flex;
      .techImg{
        order: 2;
      }
      .techText{
        order: 1;
        align-items: start;
        .techMain{
          align-items: center;
          p{
          }
        }
      }
    }
    @media (min-width: 200px) and (max-width: 768px){
      >div{
        .techImg{
          order: 1;
        }
        .techText{
          order: 2;
          align-items: flex-end;
          .techMain{
            align-items: center;
          }
        }
      }
    }
  }
  button{
    @include hiddenBtnStyle;
  }
  .techText{
    h1{
      @include techTitleStyle;
      //margin-bottom: 30px;
      span{
      @include techTitleInText;
      }
    }
    p{
      @include techTextStyle;
    }
  }
}