.slick-slider{
  width: 100%;
}
.slick-slide{
  img{
    margin: auto;
    object-fit: contain;
    width: 130px;
    height: 130px;
    @media (min-width:768px) and (max-width:1280px) {
      width: 130px;
      height: 130px;
    }
    @media (min-width:200px) and (max-width:768px){
      width: 120px;
      height: 120px;
    }
  }
}
.saleSlideBox{
  height: 275px;
  @media (min-width:200px) and (max-width:768px){
    height: 150px;
  }
}